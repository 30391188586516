// Using the old import syntax for now to ensure compatibility
// This will generate deprecation warnings but will work
// To fully eliminate these warnings would require modifying Bootstrap's
// source code directly or creating a complete custom wrapper for all Bootstrap
// components that uses the modern Sass module system with @use and @forward.
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/bootstrap";
@import "fonts";

.content {
    font-size: $custom-font-size;
}

html {
    border-top: 5px solid rgba(0, 0, 0, 1);
}

body {
    color: #000;
    font-family: Georgia, Times, "Times New Roman", serif;
    //font-size: 18px;
    line-height: 1.4782608695;

    h1 {
        font-size: 30px;
    }

    h2 {
        font-size: 26px;
    }

    h3 {
        font-size: 24px;
    }

    h4 {
        font-size: 22px;
    }

    h5 {
        font-size: 20px;
    }

    h6 {
        font-size: 19px;
    }

    pre {
        line-height: 1;
    }


    header.title {
        hgroup {
            margin-bottom: 34px;
            margin-top: 34px;
        }
        h1 {
            font-size: 60px;
            font-family: "Lato Light", "Avenir Book", sans-serif;
            letter-spacing: 0.1em;
            margin: 0;
            text-align: center;
            text-transform: uppercase;
        }
        h2 {
            font-family: "Playfair Display", serif;
            font-style: italic;
            font-size: 18px;
            font-weight: 400;
            text-align: center;
            margin: 0;
            text-align: center;
        }
        a,
        a:visited,
        a:active,
        a:hover,
        a:focus {
            color: #000;
            text-decoration: none;
        }
    }
    main {
        article {
            header {
                h1 {
                    font-family: "Lato Light", "Avenir Light", sans-serif;
                    font-style: italic;
                    font-size: 40px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    text-align: left;
                }
                a,
                a:visited,
                a:active,
                a:hover,
                a:focus {
                    color: #000;
                    text-decoration: none;
                }
            }

            section {
                a,
                a:visited,
                a:active {
                    color: #000;
                    text-decoration: none;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                }
                a:hover,
                a:focus {
                    text-decoration: none;
                    border-bottom: 1px solid rgba(0, 0, 0, 1);
                    color: #000;
                }

                blockquote {
                    padding-left: 20px;
                    padding-right: 20px;
                    font-style: italic;
                    font-family: "Playfair Display", serif;
                    font-size: 24px;
                    line-height: 1.3421052631;
                    margin-bottom: 51px;
                }
                blockquote em, blockquote i, blockquote cite {
                    font-style: normal;
                }

                .highlight {
                    background: rgb(230, 230, 230);
                    // max-height: 300px;
                    // overflow: scroll;
                    border: 1px #C1C1C1;
                    border-radius: 3px;

                    pre {
                        padding: 10px;
                        margin: 5px;
                    }
                }
            }

            aside {
                font-family: Lato, "Avenir Medium", sans-serif;
                font-weight: 400;
                font-size: 13px/1.3076923076;
                text-transform: uppercase;

                a,
                a:visited,
                a:active,
                a:hover,
                a:focus {
                    color: #000;
                    text-decoration: none;
                }
            }
        }
    }
}

// Icon
.svg-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
}

// Navigation
/* Clearing */

.clear:before,
.clear:after {
    content: "";
    display: table;
}

.clear:after {
    clear: both;
}

.navigation-paging {
    font-family: Lato, serif;
    font-size: 13px;
    line-height: 5.2307692307;
    padding: 102px 0;
    a,
    a:visited,
    a:active,
    a:hover,
    a:focus {
        color: #000;
        text-decoration: none;
    }
    .previous,
    .next {
        letter-spacing: 0.1em; //width: 49%;
        width: calc(50% - 40px);
        height: 68px;
    }
    .previous {
        float: left;
        margin: 0 0 0 34px;
        text-align: right;
    }
    .next {
        float: right;
        margin: 0 34px 0 0;
    }
    .double {
        padding-bottom: 107px;
    }
    .double .previous {
        margin: -10px 0 0 5px;
    }
    .double .next {
        margin: 5px 5px 0 0;
    }
    .meta-nav {
        -webkit-font-smoothing: antialiased;
        background-color: #000;
        border-radius: 50%;
        color: #fff;
        display: block;
        font-family: arial;
        font-size: 34px;
        line-height: 62px;
        text-align: center;
        width: 68px;
        height: 68px;
    }
    a:hover .meta-nav {
        background-color: #333;
    }
    .previous .meta-nav {
        float: right;
        margin-left: 19px;
    }
    .next .meta-nav {
        float: left;
        margin-right: 19px;
    }
    .text-nav {
        //display: none;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: uppercase;
        width: 40%;
        /* Temporarily disabled, as -webkit-calc with -webkit-transition causes Safari 6.0.3 to crash */
        /* width: -webkit-calc(100% - 87px);*/
        //width: calc(100% - 100px);
        white-space: nowrap;
    }
    .previous .text-nav {
        float: right;
    }
    .next .text-nav {
        float: left;
    }
}

pre {
    background: #f5f5f5;
    font-family: "Courier 10 Pitch", Courier, monospace;
    font-size: 19px;
    line-height: 1.7894736842;
    margin-bottom: 34px;
    padding: 34px;
    overflow: auto;
    max-width: 100%;
}

code,
kbd,
tt,
var {
    font: 12px/1.7894736842 Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
}

code,
kbd,
tt,
var {
    font: 13px/1.7894736842 Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
}

pre>code {
    font: 12px/1.7894736842 Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Avenir Medium", Lato, sans-serif;
}

